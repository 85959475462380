<template>
  <v-container>
    <v-card class="pa-8">
      <div class="d-flex align-center justify-center mb-12">
        <v-img
          :src="require('@/assets/logotype.svg')"
          max-width="300px"
          contain
        />
      </div>

      <div>
        <hr />

        <h1 class="d-flex justify-center my-6">
          Contrato de Prestação de Serviços Financeiros
        </h1>

        <hr class="mb-6" />

        <div class="mb-6">
          Contratada: EARTHGUARD PRO - NEGOCIOS LTDA , com sede em Rua Quinta de
          Novembro, n* 720, Bairro Centro, CEP 79.002-140, Campo Grande-MS,
          inscrita no CNPJ sob o nº 7, doravante denominada "Instituição",
        </div>

        <div class="mb-6">
          Contratante: {{ user.name }}, {{ user.dadosPessoais.profissao }},
          portador do CPF nº {{ formatDocument(user.dadosPessoais.cpfCnpj) }},
          residente e domiciliado à {{ user.dadosResidencial.rua }}, n*
          {{ user.dadosResidencial.numero }}
          <span v-if="user.dadosResidencial.complemento">
            - {{ user.dadosResidencial.complemento }}</span
          >, Bairro {{ user.dadosResidencial.bairro }}, CEP
          {{ formatarCEP(user.dadosResidencial.cep) }},
          {{ user.dadosResidencial.cidade }}-{{ user.dadosResidencial.estado }},
          doravante denominado "Cliente".
        </div>

        <h3 class="mb-6">CONSIDERANDO QUE:</h3>

        <div class="mb-6">
          1. A Instituição é uma entidade financeira devidamente autorizada a
          operar no mercado financeiro brasileiro, conforme regulamentações do
          Banco Central do Brasil e da Comissão de Valores Mobiliários.
        </div>

        <div class="mb-6">
          2. O Cliente deseja investir um montante de dinheiro, especificado
          neste contrato, na Instituição, com a expectativa de obter um retorno
          financeiro conforme os termos acordados.
        </div>

        <div class="mb-6">PORTANTO, é acordado o seguinte:</div>

        <h2 class="mb-6">Cláusula 1 - Objeto do Contrato</h2>

        <div class="mb-6">
          1.1 Este contrato tem por objeto a aplicação de recursos financeiros
          pelo Cliente junto à Instituição, que promete remunerar o valor
          investido à taxa de 250% do Certificado de Depósito Interbancário
          (CDI), calculado e pago mensalmente.
        </div>

        <h2 class="mb-6">Cláusula 2 - Valor do Investimento</h2>

        <div class="mb-6">
          2.1. Valor Total do Aporte: O Cliente compromete-se a aportar na
          Instituição a quantia total de R$ {{ currencyMask(contractValue) }} a
          ser realizado em moeda corrente nacional. Este aporte será efetuado em
          <span v-if="!installment"
            >uma única transação na data de assinatura deste contrato.</span
          >
          <span v-else>
            {{ installmentCount }} parcelas de {{ installmentValue }}.
          </span>
        </div>

        <div class="mb-6">
          2.2. Aporte Mínimo de Capital: O Cliente reconhece e concorda que o
          valor mínimo ermitido para aporte de capital sob os termos deste
          contrato é de R$ {{ currencyMask(property.minValue) }}. Este valor
          representa o menor montante que o Cliente está autorizado a aportar na
          Instituição para participar do programa de remuneração especificado na
          Cláusula 4 deste contrato.
        </div>

        <div class="mb-6">
          2.3. Condições de Aporte Adicional: Caso o Cliente deseje aumentar o
          montante do seu aporte inicial, este adicional deverá ser de, no
          mínimo, R$ {{ currencyMask(property.minValue) }} por transação,
          respeitando o limite máximo de aporte estabelecido pela Instituição,
          se houver.
        </div>

        <div class="mb-6">
          2.4. Mecanismo de Aporte: O valor do aporte de capital deverá ser
          depositado na conta bancária especificada pela Instituição, a qual
          será fornecida ao Cliente em comunicação direta ou através de
          documentos complementares a este contrato.
        </div>

        <div class="mb-6">
          2.5. Confirmação de Recebimento: Após o recebimento do montante
          aportado pelo Cliente, a Instituição emitirá uma confirmação de
          recebimento, detalhando o valor aportado e a data de depósito, a qual
          servirá como comprovante do aporte de capital realizado sob os termos
          deste contrato.
        </div>

        <div class="mb-6">
          2.6. Compromisso de Aporte: O Cliente declara que os recursos
          aportados são provenientes de fontes lícitas e se compromete a
          fornecer à Instituição quaisquer informações ou documentos solicitados
          para comprovação da origem dos fundos, conforme as leis e
          regulamentações aplicáveis.
        </div>

        <div class="mb-6">
          2.7. Política de Aporte de Capital: A Instituição se compromete a
          aplicar o montante aportado conforme as políticas e estratégias de
          aporte previamente definidas e comunicadas ao Cliente, visando a
          obtenção de um retorno financeiro alinhado à taxa de remuneração
          estipulada na Cláusula 4 deste contrato.
        </div>

        <h2 class="mb-6">Cláusula 3 - Prazo</h2>

        <div class="mb-6">
          3.1 O prazo deste investimento será de 12 meses, iniciando-se na data
          de efetivação do depósito do valor investido e terminando na data de
          maturidade do investimento, conforme definido neste contrato.
        </div>

        <h2 class="mb-6">Cláusula 4 - Remuneração</h2>

        <div class="mb-6">
          4.1 A remuneração sobre o valor investido será de 320% do CDI, com
          cálculo e crédito dos juros realizados mensalmente na conta
          especificada pelo Cliente.
        </div>

        <h2 class="mb-6">Cláusula 5 - Resgate</h2>

        <div class="mb-6">
          5.1. Disponibilização do Aporte na Maturidade: O valor total aportado
          pelo Cliente, acrescido da remuneração acumulada, será disponibilizado
          ao Cliente na data de maturidade do aporte de capital, conforme
          estipulado neste contrato, exceto nos casos de solicitação de resgate
          antecipado, os quais estão sujeitos às condições especificadas na
          Cláusula 6.
        </div>

        <div class="mb-6">
          5.2. Solicitação de Resgate Mensal: O Cliente está habilitado a
          solicitar o resgate da remuneração do aporte de capital de forma
          mensal, iniciando após o período de carência de 45 dias contados a
          partir da data do aporte inicial. Cada solicitação de resgate estará
          sujeita a um prazo de liquidação de 25 dias úteis, durante os quais o
          valor solicitado será processado e disponibilizado pela Instituição.
        </div>

        <div class="mb-6">
          5.3. Procedimento para Solicitação de Resgate Mensal: Para solicitar o
          resgate mensal, o Cliente deverá comunicar formalmente sua intenção à
          Instituição, especificando o valor da remuneração a ser resgatado. A
          Instituição confirmará o recebimento da solicitação de resgate e
          informará ao Cliente a data prevista para a liquidação do valor
          solicitado, conforme o prazo estipulado de 25 dias úteis.
        </div>

        <div class="mb-6">
          5.4. Condições para Resgate Mensal: O resgate mensal da remuneração é
          permitido apenas sobre os rendimentos acumulados do aporte de capital,
          não afetando o montante principal aportado pelo Cliente. O Cliente
          poderá optar por acumular a remuneração de vários meses para solicitar
          um resgate único, respeitando sempre o prazo de liquidação estipulado.
        </div>

        <div class="mb-6">
          5.5. Impacto no Valor Total de Resgate: A opção de resgate mensal da
          remuneração não altera as condições de remuneração total do aporte de
          capital, exceto conforme especificado na Cláusula 6 em caso de resgate
          antecipado do montante principal.
        </div>

        <h2 class="mb-6">Cláusula 6 - Resgate Antecipado</h2>

        <div class="mb-6">
          6.1. Possibilidade de Resgate Antecipado: O Cliente está autorizado a
          solicitar o resgate antecipado do aporte de capital a qualquer momento
          antes da data de maturidade estipulada neste contrato. Entretanto, tal
          solicitação está condicionada à incidência de penalidades ou ajustes
          na taxa de remuneração, aplicáveis de acordo com as políticas
          definidas pela Instituição.
        </div>

        <div class="mb-6">
          6.2. Estrutura da Penalidade Progressiva: - No caso de resgate do
          aporte de capital no primeiro mês após a data de aporte, será aplicada
          uma penalidade de 30% sobre o montante a ser resgatado. - A partir do
          segundo mês, a penalidade sobre o montante a ser resgatado será
          reduzida de forma progressiva, diminuindo a cada mês subsequente, até
          o décimo mês, facilitando assim, a liquidez progressiva do aporte.
        </div>

        <div class="mb-6">
          6.3. Condições Após o Décimo Mês: - Em caso de solicitação de resgate
          após o décimo mês de aporte, o Cliente concorda que a multa aplicável
          será equivalente a receber apenas 110% do CDI sobre o montante a ser
          resgatado, ao invés da taxa de remuneração inicialmente acordada. Após
          os 12 meses de contrato, o contratante poderá prosseguir com o
          contrato interpretando +1 ano de contrato.
        </div>

        <div class="mb-6">
          6.4. Procedimentos de Resgate: - Para efetivar o resgate antecipado, o
          Cliente deverá submeter um pedido formal de resgate à Instituição,
          especificando o montante desejado para resgate e a data de
          solicitação. - A Instituição processará o pedido de resgate e
          calculará o valor total devido ao Cliente, considerando o montante
          aportado, a remuneração acumulada até a data do pedido e as
          penalidades aplicáveis conforme esta cláusula.
        </div>

        <div class="mb-6">
          6.5. Transparência e Comunicação: - A Instituição compromete-se a
          informar o Cliente sobre o valor total a ser recebido após a aplicação
          das penalidades e ajustes, antes da efetivação do resgate. - O valor
          devido será disponibilizado ao Cliente na conta bancária por ele
          indicada, dentro de um prazo acordado após a solicitação de resgate.
        </div>

        <h2 class="mb-6">Cláusula 7 - Garantias do Aporte</h2>

        <div class="mb-6">
          7.1 Como garantia integral e incondicional do cumprimento de todas as
          obrigações assumidas pelo Cliente sob os termos deste contrato,
          incluindo, sem limitação, o aporte de capital na quantia de R$
          {{ currencyMask(contractValue) }}, o Cliente compromete-se a vincular
          em favor da Instituição um número específico de Unidades de
          Conservação Sustentável (UCS) Certificadas, sob o número de contrato
          {{ $route.params.id }}.
        </div>

        <div class="mb-6">
          7.2 As UCS Certificadas servirão como garantia fiduciária e serão
          mantidas sob custódia da Instituição ou de terceiro designado pela
          mesma, de acordo com os termos e condições previamente acordados entre
          as partes e em consonância com a legislação aplicável.
        </div>

        <div class="mb-6">
          7.3 A valoração das UCS Certificadas, para fins de garantia sob este
          contrato, será realizada por meio de avaliação conduzida por empresa
          independente especializada, contratada pela Instituição, com custos a
          serem suportados pelo Cliente.
        </div>

        <div class="mb-6">
          7.4 A Instituição terá o direito, mas não a obrigação, de executar a
          garantia constituída pelas UCS Certificadas, na eventualidade de
          inadimplência por parte do Cliente, de acordo com o procedimento
          estabelecido neste contrato e na legislação vigente.
        </div>

        <div class="mb-6">
          7.5 O Cliente declara ser o legítimo proprietário das UCS
          Certificadas, não estando as mesmas sujeitas a quaisquer ônus ou
          restrições de direitos, salvo aqueles declarados neste contrato.
        </div>

        <div class="mb-6">
          7.6 As partes comprometem-se a registrar este contrato e a garantia
          correspondente perante o órgão competente, se aplicável, para
          assegurar a eficácia e a oponibilidade da garantia perante terceiros.
        </div>

        <div class="mb-6">
          7.7 Quaisquer custos ou despesas relacionados à constituição,
          manutenção, avaliação e execução da garantia das UCS Certificadas
          serão de responsabilidade exclusiva do Cliente.
        </div>

        <div class="mb-6">
          7.8 Esta garantia permanecerá em vigor até a completa liquidação de
          todas as obrigações do Cliente sob este contrato, momento no qual a
          Instituição providenciará a liberação das UCS Certificadas, com todas
          as despesas associadas sendo de responsabilidade do Cliente.
        </div>

        <h2 class="mb-6">Cláusula 8 - Disposições Gerais</h2>

        <div class="mb-4">
          8.1 Este contrato está sujeito à legislação brasileira, e qualquer
          disputa relacionada a este contrato será resolvida conforme as leis do
          Brasil.
        </div>

        <div class="mb-4">Assinaturas</div>

        <div class="d-flex mb-10">
          <div class="mr-2">Contratante:</div>
          <v-divider class="mt-4" />
        </div>

        <div class="d-flex">
          <div class="mr-2">Contratada:</div>
          <v-divider class="mt-4" />
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { request } from "@/services";
import { currencyMask, formatDocument } from "@/utils";
const moment = require("moment");
moment.locale("pt-br");

export default {
  data() {
    return {
      contractName: "",
      contractValue: 0,
      depositedValue: 0,
      installment: false,
      installmentCount: 0,
      installmentValue: 0,
      contractDate: "",
      property: {},
    };
  },

  computed: {
    ...mapState(["user"]),
  },

  beforeMount() {
    this.getData();
  },

  methods: {
    async getData() {
      try {
        const payload = {
          method: "getUserContratoDetail",
          contratoID: this.$route.params.id,
        };

        await request(payload).then((res) => {
          this.contractName = res.contratoDetail.nomeContrato;
          this.contractValue = Number(res.contratoDetail.valorContrato);
          this.depositedValue = Number(res.contratoDetail.valorDeposito);
          this.contractDate = res.contratoDetail.dataContrato;
          this.installment = res.contratoDetail.contratoParcelado === "1";

          if (this.installment) {
            this.installmentCount = Number(res.contratoDetail.numParcelas);
            this.installmentValue = Number(
              res.contratoDetail.parcelasInfo[0].valor
            );
          }

          this.getContracts();
        });
      } catch (err) {
        console.log(err);
      }
    },

    async getContracts() {
      try {
        const payload = {
          method: "getAvaliablesContratos",
          page: 1,
        };

        await request(payload).then((res) => {
          const property = res.contratos.find(
            (el) => el.nomeContrato === this.contractName
          );

          this.property = {
            id: property.id,
            title: property.nomeContrato,
            resources: property.listaBeneficios,
            location: property.politicaOperacional,
            sharesSold: property.cotaVendida,
            description: property.objetivo,
            comment: property.descRendimentoContrato,
            minValue: property.minDeposito,
            maxValue: property.maxDeposito,
            images: property.imagens,
            contract: property.linkPDF,
          };

          if (this.$route.params.id) this.handleProperty();
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    formatDate(value) {
      const data = moment(value, "YYYY-MM-DD");
      return data.format("DD [de] MMMM [de] YYYY");
    },

    formatarCEP(cep) {
      cep = cep.replace(/\D/g, "");
      return cep.replace(/^(\d{5})(\d{3})$/, "$1-$2");
    },

    currencyMask,

    formatDocument,
  },
};
</script>
